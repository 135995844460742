<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
        @selection-change="selectionChange"
    >
      <template #header>
        <div class="flex align-items marginBottom20 flex-wrap justify-content-space-between">
          <div>
            <el-input size="medium" v-model="searchData.name" :maxlength="100" placeholder="输入姓名" clearable class="marginRight10 width300"/>
            <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginRight10">搜索</el-button>
          </div>
          <el-button size="medium" type="primary" icon="el-icon-receiving" @click="handleAllExamine" class="marginRight10">批量审核</el-button>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" :disabled=" row.state !== 1 " size="mini" icon="el-icon-help" @click="handleExamine(row)">审核</el-button>
      </template>
    </avue-crud>

    <el-dialog
        title=""
        v-if="show"
        :visible.sync="show"
        width="30%"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 @submit="handleSubmit">
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { option } from "./data";
import { list ,update, batchUpdate } from "@/api/agent/examine"
export default {
  name: "index",
  data(){
    return{
      searchData: {
        name: '',
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option: option,

      multipleSelection: [],

      typeExamine: false, // false为审核  true为批量审核
      idExamine: '',
      show: false,
      form: {
        state: 2,
        examineExplain: ''
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: '审核',
            prop: 'state',
            type: 'radio',
            value: 2,
            span: 24,
            dicData: [
              {
                label: '审核通过',
                value: 2
              },
              {
                label: '审核驳回',
                value: 3
              }
            ],
            rules: [
              {
                required: true,
                message: '请选择审核结果',
                trigger: 'change'
              }
            ],
            change: (e)=>{
              this.formOption.column[1].rules[0].required = e.value === 3;
              e.value === 2 && this.$refs.form.clearValidate(`examineExplain`);
            }
          },
          {
            label: '审核说明',
            prop: 'examineExplain',
            span: 24,
            type: 'textarea',
            maxlength: 30,
            showWordLimit: true,
            rules: [{
              required: false,
              message: '请填写审核说明',
              trigger: 'change'
            }]
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res => {
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 搜索
    handleSearch(){
      this.page.currentPage = 1;
      this.onLoad()
    },
    // 多选
    selectionChange(val){
      // console.log(val)
      this.multipleSelection = val.map(item=>item.id);
      console.log(this.multipleSelection)
    },
    // 审核
    handleExamine(row){
      // console.log('审核')
      this.typeExamine = false;
      this.show = true;
      this.idExamine = row.id;
    },
    // 批量审核
    handleAllExamine(){
      if(this.multipleSelection.length === 0){
        this.$message.warning('请选择需要审核的数据')
      }else{
        // console.log(this.multipleSelection)
        this.typeExamine = true;
        this.show = true;
      }
    },
    // 提交审核
    handleSubmit(form,done){
      // console.log(form)
      if (this.typeExamine){
        batchUpdate({
          id: this.multipleSelection.toString(),
          ...form
        }).then(res => {
          if(res.code === 200){
            this.$message.success('审核成功');
            this.show = false;
            this.onLoad();
          }
        })
      }else{
        update({
          id: this.idExamine,
          ...form
        }).then(res=>{
          if(res.code === 200){
            this.$message.success('操作成功')
            this.show = false;
            this.resetForm();
            this.onLoad()
          }else{
            this.$message.success(res.msg)
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭审核弹框
    handleClose(){
      // console.log('关闭审核弹框')
      this.show = false
      this.resetForm()
    },
    // 重置form
    resetForm(){
      this.form = {
        state: 2,
        examineExplain: ''
      }
      this.idExamine = '';
    }
  }
}
</script>

<style scoped>
/deep/ .avue-crud .avue-crud__tip{
  display: none;
}
</style>