export const option = {
    title: '审核管理',
    titleSize: 'h3',
    card: true,
    border: true,
    header: false,
    stripe: false, // 显示斑马纹
    showHeader: true, // 显示表头
    index: false, // 显示索引
    size: 'small',
    selection: true, // 显示多选框
    selectable:(row)=>{
        return row.state === 1;
    },
    page: true, // 显示分页
    align: 'center',
    menuAlign: 'center',
    menuType: 'text',
    editBtn: false,
    delBtn: false,
    column: [
        {
            label: '提交时间',
            prop: 'createTime',
            width: 200
        },
        {
            label: '用户手机号',
            prop: 'phone',
            width: 200
        },
        {
            label: '用户id',
            prop: 'code',
            width: 150
        },
        {
            label: '用户姓名',
            prop: 'username',
            width: 150
        },
        {
            label: '身份证号',
            prop: 'idCard',
            width: 200
        },
        {
            label: '所在区域',
            prop: 'provinceName',
            formatter: function(row, column, cellValue){
                return cellValue + row.cityName + row.county + row.business;
            },
            width: 200
        },
        {
            label: '相关证书',
            prop: 'certImage',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            width: 200
        },
        {
            label: '个人介绍',
            prop: 'personIntro',
            width: 200
        },
        {
            label: '身份证正反面',
            prop: 'idCardImages',
            type: 'upload',
            dataType: 'string',
            listType: 'picture-card',
            width: 200
        },
        {
            label: '状态',
            prop: 'state',
            formatter: function(row, column, cellValue){
                return cellValue === 1 ? '待审核' : cellValue === 2 ? '审核通过' : cellValue === 3 ? '审核拒绝' : '/';
            },
            width: 150
        },
        {
            label: '审核时间',
            prop: 'examineTime',
            width: 200
        },
        {
            label: '说明',
            prop: 'examineExplain',
            width: 200
        }
    ]
}